<template>
  <div class="page">
    <el-col :span="12">
      <el-button
        type="primary"
        size="small"
        @click="getTranslationInfoMultiple"
      >
        批量翻译
      </el-button>
    </el-col>
    <el-col :span="12" style="text-align: right">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item>
          <el-input
            v-model="queryForm.keyword"
            type="text"
            clearable
            size="small"
            placeholder="请输入关键字"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            @click="queryData"
            type="primary"
            size="small"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>

    <el-table
      v-loading="listLoading"
      :data="list.filter(data => !search || data.func_cate.toLowerCase().includes(search.toLowerCase()))"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column align="center" prop="kt_id" label="ID" width="80">
      </el-table-column>
        <el-table-column align="center" prop="func_cate" label="类型" width="220">
            <template #header>
                <div style="display: flex;align-items: center">
                    <span style="flex-shrink: 0;margin-right: 5px">类型</span>
                    <el-input v-model="search" size="small" placeholder="Type to search" />
                </div>
            </template>
        </el-table-column>
      <el-table-column
        align="center"
        prop="keyword"
        label="源语言"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="目标语言"
        show-overflow-tooltip
        :filters="[
          { text: '已翻译', value: 1 },
          { text: '未翻译', value: 2 },
        ]"
        :filter-multiple="false"
        :filter-method="filterHandler"
      >
        <template slot-scope="scope">
          <el-input v-model="scope.row.translate" size="small" />
        </template>
      </el-table-column>
        <el-table-column align="center" label="翻译后中文">
            <template slot-scope="scope">
                <el-input v-model="scope.row.translateCN" size="small" readonly />
            </template>
        </el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="getTranslationInfo(scope.row, scope.$index)"
            >google翻译</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: flex-end; margin-top: 10px">
      <el-button
        type="primary"
        size="small"
        style="margin-top: 10px"
        @click="handleSave"
        >保 存</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  apiGetKeywordList,
  apiGetTranslateDescr,
  apiSaveTranslateDescr,
} from "@/request/api";

export default {
  name: "translationlist",
  data() {
    return {
      selectRows: [],
      listLoading: false,
      code: this.$route.query.code,
      queryForm: {
        keyword: "",
        code: this.$route.query.code,
      },
      list: [],
        search: ''
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {},
  methods: {
    filterHandler(value, row) {
      if (value == 0) {
        return true;
      }
      if (value == 1) {
        return row.translate ? true : false;
      }
      if (value == 2) {
        return row.translate ? false : true;
      }
    },
    //翻译内容
    getTranslationInfoMultiple() {
      let that = this;
      const tempArr = [];
      console.log(this.selectRows);
      this.selectRows.forEach((item) => {
        tempArr.push({
          origin_id: item.origin_id,
          keyword: item.keyword,
          code: item.code,
        });
      });
      apiGetTranslateDescr({
        keywords: tempArr,
        to_code: this.$route.query.config,
      }).then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          const arr = res.data;
          arr.forEach((temp) => {
            that.list.forEach((item) => {
              if (item.origin_id == temp.origin_id && item.origin_id) {
                item.translate = temp.keyword;
                item.translateCN = temp.chinese;
              }
            });
          });
        }
      });
    },
    getTranslationInfo(row, index) {
      let that = this;
      const tempObj = { keyword: row.keyword, code: row.code };
      apiGetTranslateDescr({
        keywords: [tempObj],
        to_code: this.$route.query.config,
      }).then((res) => {
        if (res.code == 200) {
          that.list[index].translate = res.data[0].keyword;
          that.list[index].translateCN = res.data[0].chinese;
        }
      });
    },
    //保存翻译
    handleSave() {
      const tempArr = [];
      this.list.forEach((item) => {
        if (item.translate) {
          tempArr.push({
            origin_id: item.origin_id,
            kt_id: item.kt_id,
            value: item.translate,
          });
        }
      });
      apiSaveTranslateDescr({
        keywords: tempArr,
        to_code: this.$route.query.code,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.fetchData();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    queryData() {
      this.queryForm.page = 1;
      this.fetchData();
    },
    async fetchData() {
      this.listLoading = true;
      apiGetKeywordList(this.queryForm).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
      this.listLoading = false;
    },
  },
};
</script>
<style scoped>
.page {
  background-color: #fff;
  padding: 20px;
}
</style>
